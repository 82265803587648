import { createRoot } from 'react-dom/client';
import React from 'react';
import Header from '@components/header';
import NavBar from '@components/navbar';
import FeatureSection from '@components/featuresection';
import Feature from '@components/feature';
import Footer from '@components/footer';
import { GiStoneCrafting, GiMetalPlate, GiMeltingMetal } from 'react-icons/gi';

const root = createRoot(document.getElementById('app') as HTMLElement);

root.render(
  <React.StrictMode>
    <div className="bg-hero-pattern bg-cover bg-fixed bg-center bg-no-repeat">
      <NavBar current="/" />
      <Header>
        <Header.Super>Ottab Technologies</Header.Super>
        <Header.Body>
          <p>Cutting edge since 1980</p>
        </Header.Body>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <a
            href="#main"
            className="rounded-sm bg-blue-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-400"
          >
            Get started
          </a>
          <a
            href="/team.html"
            className="text-sm font-semibold leading-6 text-white"
          >
            Meet our team <span aria-hidden="true">→</span>
          </a>
        </div>
      </Header>
      <div className="bg-transparent" id="main">
        <FeatureSection>
          <FeatureSection.Header>
            <FeatureSection.Title>Ottab Technologies</FeatureSection.Title>
            <FeatureSection.SubTitle>What We Do</FeatureSection.SubTitle>
            <FeatureSection.Description>
              <p>
                At Ottab Technologies, we're at the forefront of developing
                groundbreaking software and hardware systems for the global
                mining and steel industries. <br />
                <br />
                Our passion lies in enhancing efficiency and driving
                profitability for our clients. From the hands-on workers in
                casting mills to esteemed laboratories, our innovative solutions
                empower users across the entire spectrum.
                <br />
                <br />
                Check out our solutions below.
              </p>
            </FeatureSection.Description>
          </FeatureSection.Header>
          <FeatureSection.Body>
            <Feature>
              <Feature.Header>
                <GiStoneCrafting className="-mr-1.5 mt-0.5 h-6 w-6 text-blue-500" />
                Mineral Liberation
              </Feature.Header>
              <Feature.Body>
                <Feature.Description>
                  Fast automated mineral liberation analysis
                </Feature.Description>
                <Feature.Link href="/minlib.html" />
              </Feature.Body>
            </Feature>
            <Feature>
              <Feature.Header>
                <GiMeltingMetal className="-mr-1.5 mt-1 h-6 w-6 text-blue-500" />
                Inclusion Classifier
              </Feature.Header>
              <Feature.Body>
                <Feature.Description>
                  State of the art classifying non-metallic inclusions in steel
                </Feature.Description>
                <Feature.Link href="/inclusion.html" />
              </Feature.Body>
            </Feature>
            <Feature>
              <Feature.Header>
                <GiMetalPlate className="-mr-1.5 mt-0.5 h-6 w-6 text-blue-500" />
                Slabs
              </Feature.Header>
              <Feature.Body>
                <Feature.Description>
                  Automated classification of steel slabs
                </Feature.Description>
                <Feature.Link href="/slabs.html" />
              </Feature.Body>
            </Feature>
          </FeatureSection.Body>
        </FeatureSection>
      </div>
      <Footer current="/" />
    </div>
  </React.StrictMode>
);
